@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800&display=swap');

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.eot');
  src:
    url('./assets/fonts/icomoon.ttf') format('truetype'),
    url('./assets/fonts/icomoon.woff') format('woff'),
    url('./assets/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

#root {
  min-height: 100vh;
  display: flex;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@import url('./styles/indents.scss');

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

html {
  font-size: 100%;
}

@media screen and (max-width: 1450px) and (min-width: 768px) {
  html {
    font-size: 85%;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:root {
  --app-height: 100vh;
  --notes-width: 50em;
  --page-width: 1600px;
  --page-gap: 1em;
  --background-blur: 15px;
  --background-brightness: 0.8;
  --load-time-transition: 0ms;
  --average-color: black;
  --text-shadow-alpha: 0.2;
  --notes-background-alpha: 0.1;
  --searchbar-background-alpha: 0.1;
  --font-family: -apple-system, system-ui, Ubuntu, Roboto, 'Open Sans', 'Segoe UI', 'Helvetica Neue';
  --font-weight-clock: 200;
  --font-weight: 400;
  --color-black: #000000;
  --color-red: #fe3b31;
  --color-green: #2ed058;
  --color-success: #00bf57;
  --color-success-1: #0faf59;
  --color-success-light: #d1ffe6;
  --color-border: #575f82;
  --color-warning: #f5d883;
  --color-warning-1: #c6821f;
  --color-warning-2: #b8a30c;
  --color-warning-light: #f9ed96;
  --color-warning-dark: #ffb74d;
  --color-text-error: #ec5050;
  --color-error: #ffd9d9;
  --color-warning: #ffebce;
  --color-success-dark: #0a9349;
  --color-success-dark-1: #047a3b;
  --color-grey-1: #1c1f2d;
  --color-grey-2: #282c3e;
  --color-select-grey-1: #282c3e;
  --color-grey-3: #2b3040;
  --color-grey-4: #353a4d;
  --color-grey-dropdown: #424865;
  --color-grey-5: #6d7499;
  --color-grey-5-rgb: #{hexToRGB(#6d7499)};
  --color-grey-6: #43485f;
  --color-grey-7: #43485f;
  --color-link: #4a8ded;
  --color-info: #2b8bfc;
  --color-text: #ffffff;
  --color-text-secondary: #858585;
  --color-border-dark: #383d57;
  --color-text-disabled: #ffffff4a;
  --color-bg-disabled: #373c53;
  --color-text-bronze: #a4660a;
  --color-text-reverse: #1c1f2d;
  --input-date-color: dark;
  --color-disabled: #ffffff1a;
  --color-card-grey: #454a5e;
}

html[data-theme='light'] {
  --color-grey-1: #ffffff;
  --color-grey-2: #f0f0f0;
  --color-grey-3: #f0f0f0;
  --color-grey-4: #f0f0f0;
  --color-border: #c8cad3;
  --color-border-dark: #c8cad3;
  --color-grey-dropdown: #dbdbdb;
  --color-grey-5: #c0c0c0;
  --color-grey-5-rgb: #{hexToRGB(#c0c0c0)};
  --color-grey-6: #f9f9f9;
  --color-grey-7: #1c1f2d;
  --color-text: #1c1f2d;
  --color-text-reverse: #ffffff;
  --input-date-color: light;
  --color-select-grey-1: #ffffff;
  --color-card-grey: #ffffff;
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

body {
  background: var(--color-grey-1) !important;
  color: var(--color-text) !important;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: var(--color-text);
}

*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c0c0c4;
}

button {
  border: none;
  background: transparent;
  color: var(--color-text);
  cursor: pointer;
  padding: 12px 0px;
  border-radius: 10px;
}

ul {
  list-style: none;
}

input::-webkit-datetime-edit {
  width: 100%;
}

.ant-input {
  background: transparent !important;
  border: 1px solid var(--color-border-dark);
  color: var(--color-text);
  &::placeholder {
    color: var(--color-grey-5);
  }
}

.ant-checkbox-inner {
  background-color: var(--color-grey-5) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-text-secondary) !important;
  border-color: var(--color-text-secondary) !important;
}

.ant-input {
  font-size: 16px;
  height: 50px;
}

.ant-input:focus {
  box-shadow: none !important;
}

.ant-input:hover,
.ant-input:focus,
.ant-checkbox-inner,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-select-open,
.ant-select.ant-select-in-form-item,
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.ant-select-focused .ant-select-selector {
  border-color: var(--color-grey-5) !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.ant-drawer:focus-visible {
  outline: none !important;
}

.ant-input-affix-wrapper {
  padding: 0px 10px;
  background: transparent !important;
  border: 1px solid var(--color-border-dark);
}

.ant-input-suffix,
.ant-select-arrow {
  color: var(--color-grey-5) !important;
  cursor: pointer;
}

.ant-select {
  height: 50px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent !important;
  border: 1px solid var(--color-border-dark);
}

.ant-select-selection-placeholder {
  color: var(--color-grey-5) !important;
}

.ant-select-single,
.ant-select-selector {
  color: var(--color-text) !important;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-skeleton-input,
.ant-skeleton-avatar,
.ant-skeleton-title,
.ant-skeleton-paragraph {
  background: var(--color-border) !important;
}

.ant-skeleton.ant-skeleton-element {
  width: 100%;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-weight: 400;
}
.ant-upload-wrapper .ant-upload-drag {
  border: none;
}

.ant-upload-drag .ant-upload-btn {
  border: 1px dashed var(--color-border);
  background-color: rgb(var(--color-grey-5-rgb), 0.1);
}

.ant-modal {
  max-width: 85%;
}

.ant-spin {
  color: var(--color-text);
  .ant-spin-dot-item {
    background-color: var(--color-text);
  }
}

.custom_notification {
  padding: 12px 0px 12px 12px !important;
  width: 350px !important;

  .ant-notification-notice-close {
    top: 10px !important;
    inset-inline-end: 14px !important;
  }
}

.trade_entered_notification {
  top: 20%;
  left: 20%;
}

.trade_closed_notification {
  bottom: 20%;
  left: 20%;
}

.ant-notification {
  left: 120px !important;

  @media screen and (max-width: 768px) {
    left: auto !important;
  }
}

input {
  cursor: pointer;
}

input[type='date'] {
  color-scheme: var(--input-date-color);
}
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  padding: 32px 0;
  padding-left: 5%;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 10%;
}
input::-webkit-datetime-edit {
  position: relative;
  left: 8px;
}

.ant-alert-message {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px !important;
}

.ant-alert {
  border: none;
  border-radius: 10px;
  padding: 12px;
}

.ant-alert-success {
  background-color: var(--color-success-light);
}
.ant-alert-info {
  background-color: var(--color-warning);
}
.ant-alert-warning {
  background-color: var(color-warning-light);
}
.ant-alert-error {
  background-color: var(--color-error);
}

.ant-alert-success .ant-alert-content > div,
.ant-alert-success .ant-alert-icon {
  color: var(--color-success-1) !important;
}

.ant-alert-info .ant-alert-content > div,
.ant-alert-info .ant-alert-icon {
  color: var(--color-warning-1) !important;
}
.ant-alert-warning .ant-alert-content > div,
.ant-alert-warning .ant-alert-icon {
  color: var(--color-warning-2) !important;
}
.ant-alert-error .ant-alert-content > div,
.ant-alert-error .ant-alert-icon {
  color: var(--color-text-error) !important;
}

.ant-modal-content .ant-modal-footer {
  border-top: none !important;
  margin-top: 0;
}

.ant-upload-drag .ant-upload {
  padding: 32px !important;
}

.copyPopover {
  .ant-popover-inner {
    padding: 6px;

    .ant-popover-title {
      margin-bottom: 0px;
      font-weight: 400;
    }
  }
}

.ant-radio-wrapper .ant-radio-inner {
  border-color: var(--color-text) !important;
}
